import React from "react";
import CircleBLur from "@common/CircleBlur/CircleBlur";
import useWindowDimensions from "@hooks/useWindowDimensions";
import WorkingRoomDesk1x from "@images/office/working-room-desk-1x.jpg";
import WorkingRoomDesk2x from "@images/office/working-room-desk-2x.jpg";
import WorkingRoomDesk3x from "@images/office/working-room-desk-3x.jpg";
import WindowViewDesc1x from "@images/office/window-view-desk-1x.jpg";
import WindowViewDesc2x from "@images/office/window-view-desk-2x.jpg";
import WindowViewDesc3x from "@images/office/window-view-desk-3x.jpg";
import WorkingStaffDesk1x from "@images/office/working-staff-desk-1x.jpg";
import WorkingStaffDesk2x from "@images/office/working-staff-desk-2x.jpg";
import WorkingStaffDesk3x from "@images/office/working-staff-desk-3x.jpg";
import StairsDesc1x from "@images/office/stairs-desc-1x.jpg";
import StairsDesc2x from "@images/office/stairs-desc-2x.jpg";
import StairsDesc3x from "@images/office/stairs-desc-3x.jpg";
import MeetingRoomDesc1x from "@images/office/meeting-room-desc-1x.jpg";
import MeetingRoomDesc2x from "@images/office/meeting-room-desc-2x.jpg";
import MeetingRoomDesc3x from "@images/office/meeting-room-desc-3x.jpg";
import OpenDoorDesc1x from "@images/office/open-door-desc-1x.jpg";
import OpenDoorDesc2x from "@images/office/open-door-desc-2x.jpg";
import OpenDoorDesc3x from "@images/office/open-door-desc-3x.jpg";
import PiecDesc1x from "@images/office/piec-desc-1x.jpg";
import PiecDesc2x from "@images/office/piec-desc-2x.jpg";
import PiecDesc3x from "@images/office/piec-desc-3x.jpg";
import LoungeDesc1x from "@images/office/lounge-desc-1x.jpg";
import LoungeDesc2x from "@images/office/lounge-desc-2x.jpg";
import LoungeDesc3x from "@images/office/lounge-desc-3x.jpg";
import {
    REVERSE_IMAGES_COLLAGE,
    TABLET_MIN_WIDTH,
    TABLET_MAX_WIDTH,
    SHIFTED_IMAGES_COLLAGE,
} from "@constants";
import ImagesCarousel from "./components/ImagesCarousel/ImagesCarousel";
import ImagesCollage from "./components/ImagesCollage/ImagesCollage";
import OfficeHeading from "./components/OfficeHeading/OfficeHeading";
import JoinTeam from "./components/JoinTeam/JoinTeam";
import "./Office.scss";

const Office = () => {
    const { width } = useWindowDimensions();
    const isMobile = width < TABLET_MIN_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH;

    const firstCollage = {
        mainImageInfo: {
            src: WorkingRoomDesk1x,
            srcSet: `${WorkingRoomDesk2x} 2x, ${WorkingRoomDesk3x} 3x`,
            altText: "main office impressit",
        },
        rightImageInfo: null,
        leftImageInfo: null,
    };

    const secondCollage = {
        mainImageInfo: {
            src: StairsDesc1x,
            srcSet: `${StairsDesc2x} 2x, ${StairsDesc3x} 3x`,
            altText: "lounge zone impressit",
        },
        rightImageInfo: {
            src: WindowViewDesc1x,
            srcSet: `${WindowViewDesc2x} 2x, ${WindowViewDesc3x} 3x`,
            altText: "reliable software development partner",
        },
        leftImageInfo: {
            src: WorkingStaffDesk1x,
            srcSet: `${WorkingStaffDesk2x} 2x, ${WorkingStaffDesk3x} 3x`,
            altText: "impressit live",
        },
    };

    const thirdCollage = {
        mainImageInfo: {
            src: MeetingRoomDesc1x,
            srcSet: `${MeetingRoomDesc2x} 2x, ${MeetingRoomDesc3x} 3x`,
            altText: "Big Meeting Room",
        },
        rightImageInfo: {
            src: PiecDesc1x,
            srcSet: `${PiecDesc2x} 2x, ${PiecDesc3x} 3x`,
            altText: "Stove - First Open Space",
        },
        leftImageInfo: {
            src: OpenDoorDesc1x,
            srcSet: `${OpenDoorDesc2x} 2x, ${OpenDoorDesc3x} 3x`,
            altText: "Entrance - Big Meeting Room",
        },
    };

    const fourthCollage = {
        mainImageInfo: {
            src: LoungeDesc1x,
            srcSet: `${LoungeDesc2x} 2x, ${LoungeDesc3x} 3x`,
            altText: "Lounge Zone",
        },
        rightImageInfo: null,
        leftImageInfo: null,
    };

    const thirdCollageText =
        "The look of our office combines the beauty and uniqueness\n" +
        "of this historical building with the contemporary interior design. As a tech company,\n" +
        "we strive to keep the pace while also believe that it's essential to preserve the attainments of the past.";

    const imagesCarousel = [];
    imagesCarousel.push(thirdCollage);
    imagesCarousel.push(fourthCollage);

    return (
        <div className="office">
            <CircleBLur
                blur="blur(400px)"
                width="570px"
                left="-280px"
                showOnTablet={false}
                height="570px"
            />
            <OfficeHeading />
            <ImagesCollage collageImages={firstCollage} isLazyLoad={false} />
            <ImagesCollage
                imagesDescription="Rudanskoho street is a cosy and special place in the centre
                of the city. We chose a building that, for a long time, was a meeting place for local musicians
                and hosted a bookstore for almost 40 years. Creativity and art are still in the air,
                inspiring us every day, even in the smallest tasks."
                collageType={REVERSE_IMAGES_COLLAGE}
                collageImages={secondCollage}
                isLazyLoad={!isTablet}
            />
            {!isMobile && (
                <>
                    <ImagesCollage
                        imagesDescription={thirdCollageText}
                        collageImages={thirdCollage}
                    />
                    <ImagesCollage
                        collageImages={fourthCollage}
                        shiftType={SHIFTED_IMAGES_COLLAGE}
                    />{" "}
                </>
            )}
            {isMobile && (
                <ImagesCarousel
                    imagesDescription={thirdCollageText}
                    imagesCarousel={imagesCarousel}
                />
            )}
            <JoinTeam />
        </div>
    );
};

export default Office;
