import React, { useCallback } from "react";
import Image from "@common/Image/Image";
import Button from "@common/Button/Button";
import "./JoinTeam.scss";
import { navigate } from "gatsby";
import { CAREERS_PATH } from "@constants";

const JoinTeam = () => {
    const handleClick = useCallback(() => {
        navigate(CAREERS_PATH);
        window.leadToOpenPositionSection = true;
    }, [navigate]);

    return (
        <div className="join-team">
            <p className="join-team__heading">
                Looking for your dream job?
                <br />
                Join the Impressit team!
            </p>
            {/* <Image
                src={TeamDesc1x}
                className="join-team__image"
                alt="Join-our-team image"
                srcSet={`${TeamDesc2x} 2x, ${TeamDesc3x} 3x`}
            /> */}
            <div className="join-team__btn-container">
                <Button text="View open positions" handleClick={handleClick} />
            </div>
        </div>
    );
};

export default JoinTeam;
